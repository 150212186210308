import React from "react";
import classNames from "classnames";
import useCareersData from "@staticQueries/CareersQuery";
import { Container, Text, ButtonAlt, Button } from "@atoms";

const CareerList = ({
  bgColor,
  careers: _careers,
  color,
  copy,
  heading,
  link,
  overlap,
}) => {
  const isDark =
    color === "pink" ||
    color === "purple" ||
    color === "darkTeal" ||
    color === "darkerTeal" ||
    color === "black";

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const careers = _careers?.length ? _careers : useCareersData();

  return (
    <Container className="-mt-10 sm:-mt-20">
      <div className={classNames("absolute inset-x-0 h-10 sm:h-20", bgColor)} />
      <div
        className={classNames(
          "relative z-10 rounded-lg p-4 sm:p-6 md:p-8 lg:p-12 xl:p-16",
          {
            "bg-teal-lighter": color === "lighterTeal",
            "bg-teal-light": color === "lightTeal",
            "bg-teal": color === "teal",
            "bg-teal-dark": color === "darkTeal",
            "bg-black": color === "black",
            "bg-pink": color === "pink",
            "bg-purple": color === "purple",
          }
        )}
      >
        <div
          className={classNames(
            "flex flex-wrap gap-10 md:flex-nowrap md:gap-20",
            {
              "text-white": isDark,
            }
          )}
        >
          <div className="flex flex-grow flex-col items-start gap-5 md:flex-[0_1_calc(50%-5rem)]">
            <Text variant="h2">{heading}</Text>
            <Text
              richText
              className={classNames({
                "prose-white": isDark,
              })}
            >
              {copy}
            </Text>
            {link?.url && (
              <ButtonAlt to={link.url} color={color}>
                {link.text}
              </ButtonAlt>
            )}
          </div>
          <div className="flex flex-grow flex-col items-start gap-10 md:flex-1">
            {careers?.map(career => {
              const { uid, title, descriptor, url } = career;
              return (
                <div key={uid} className="flex flex-col gap-3">
                  <Text variant="h6">{title}</Text>
                  <Text variant="sm">{descriptor}</Text>
                  {url && (
                    <ButtonAlt to={url} color={color}>
                      Learn more & apply
                    </ButtonAlt>
                  )}
                </div>
              );
            })}
            {careers?.length > 3 && (
              <Button to="/careers" color="pink">
                View all
              </Button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

CareerList.defaultProps = {
  overlap: false,
};

export default CareerList;
