import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query CareersQuery {
    craft {
      entries(section: "careers", limit: 3) {
        ... on Craft_careers_default_Entry {
          uid
          url
          title
          descriptor: metaDescription
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) => entries;

const useData = () => {
  const { craft: response } = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
